
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import { format12 } from '@/utils/dateFormat';
  import moment from 'moment/moment';

  export default defineComponent({
    name: 'customer-attendance-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const startTime = ref();
      const endTime = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate('Daily Schedule Report For Trainers'),
          [translate('reports')]
        );
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue('lessonReport');
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('lessonReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.startTime) startTime.value = dataSaved.startTime;
          if (dataSaved.endTime) endTime.value = dataSaved.endTime;
          if (dataSaved.groomerId) selectedGroomer.value = dataSaved.groomerId;
          if (dataSaved.dayId) selectedDay.value = dataSaved.dayId;
          if (dataSaved.typeId) selecteType.value = dataSaved.typeId;
          if (dataSaved.durationId)
            selecteDuration.value = dataSaved.durationId;
          if (dataSaved.attendanceId)
            selectedAttendance.value = dataSaved.attendanceId;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.trainerId) selectedTrainer.value = dataSaved.trainerId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('lessonReport');
        selectedCustomer.value = 0;
        selectedTrainer.value = 0;
        selectedHorse.value = 0;
        selectedArena.value = 0;
        selectedAttendance.value = 0;
        selecteDuration.value = 0;
        selecteType.value = 0;
        selectedDay.value = 0;
        selectedGroomer.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
        startTime.value = null;
        endTime.value = null;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        values.startDate = String(values.startDate);
        values.endDate = String(values.endDate);

        searchService.setSearchValue('lessonReport', values);
        const columnStyles = {};

        columnStyles[10] = {
          halign: 'left',
          cellWidth: 50,
          overflow: 'linebreak',
        };

        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_LESSONS, values);

        reportData.value = store.getters.getLessons;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'landscape',
        });
        const fontSize = 9;

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        const pageHeight = doc.internal.pageSize.getHeight();
        let currentY = 35;
        const footerHeight = 5; // Ensure 20 units of space for footer

        reportData.value.data.forEach(function (element, index) {
          const resTabelBody = [];
          const headerHeight = 8;
          const rowHeight = 5;
          const estimatedTableHeight = headerHeight + rowHeight + 10;

          // Check if the current page has enough space for the next trainer's table
          if (currentY + estimatedTableHeight + footerHeight > pageHeight) {
            doc.addPage();
            currentY = 35; // Reset the Y position after adding a new page
          }

          // Add trainer details
          doc.setFontSize(7);
          doc.text('Trainer: ' + element['trainerName'], 10, currentY);
          doc.text('Lesson Date: ' + element['date'], 220, currentY);
          currentY += headerHeight;
          doc.text('Count : ' + element.lessons.length, 130, currentY - 5);

          // Prepare table data
          element.lessons.forEach((lesson, index) => {
            resTabelBody.push([
              index + 1,
              lesson['customerName'],
              lesson['horseName'],
              format12(lesson['time']['startTime']),
              format12(lesson['time']['endTime']),
              lesson['arenaName'],
              lesson['horseLocation'],
              lesson['lessonType'],
              lesson['groomerName'],
              lesson['attendance'],
              lesson['comment'],
              lesson['phoneNumber'],
              {
                content: lesson['classLevelName']
                  ? lesson['classLevelName']
                  : '',
                styles: { fillColor: lesson['color'] },
              },
            ]);
          });

          autoTable(doc, {
            head: [
              [
                'Sr',
                'Customer',
                'Horse',
                'Start Time',
                'End Time',
                'Arena',
                'Location / Prev Arena',
                'Lesson Type',
                'Groomer',
                'Status',
                'Comment',
                'Phone Number',
                'Class Level',
              ],
            ],
            body: resTabelBody,
            startY: currentY,
            margin: { horizontal: 5, top: 35 },
            horizontalPageBreak: false,
            theme: 'grid',
            headStyles: {
              fillColor: [0, 72, 115],
              fontStyle: 'bold',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 6.5,
            },
            bodyStyles: {
              fontStyle: 'normal',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 6.5,
            },
            columnStyles: columnStyles,
            pageBreak: 'auto',
            didDrawPage: function (data) {
              currentY = data.cursor.y;
              pageCount++;
            },
          });

          currentY += 5; // Add some space between tables
        });

        // Add footer, logo, and other details
        const logoImage = await loadImage('/media/logos/empty-krc.png');
        Array.from({ length: pageCount }, (x, i) => {
          doc.addImage(logoImage, 'png', 5, 3, 30, 30);
          doc.setFontSize(12);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 16);
          doc.setFontSize(10);
          doc.text('Daily Schedule Report For Trainers', textX, 22);
          doc.setFontSize(8);
          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 7);
          doc.setFontSize(7);
          doc.text(
            'Start Date: ' + moment(values.startDate).format('LL'),
            95,
            30
          );
          doc.text('End Date: ' + moment(values.endDate).format('LL'), 140, 30);
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);
          doc.setFontSize(8);
          const pageCountWidth = doc.getTextWidth(String(i + 1));
          doc.text(
            'Page ' + (i + 1),
            textX - pageCountWidth - 2,
            doc.internal.pageSize.height - 9
          );
          doc.text(
            ' of ' + doc.getNumberOfPages(),
            textX + 5,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');
      };

      const validationSchema = Yup.object().shape({});

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        selectedGroomer,
        startDate,
        endDate,
        startTime,
        endTime,
        loadingPage,
        clearSearch,
      };
    },
  });
